/**************** OFFERS *********************/
export const ACTION_OFFER = "ACTION_OFFER";
export const CREATE_OFFER = "CREATE_OFFER";
export const ERROR_CREATE_OFFER = "ERROR_CREATE_OFFER";

export const ACTION_FETCH_OFFER = "ACTION_FETCH_OFFER";
export const FETCH_OFFER = "FETCH_OFFER";
export const ERROR_FETCH_OFFER = "ERROR_FETCH_OFFER";
export const CLEAR_OFFER = "CLEAR_OFFER";

export const UPDATE_OFFER = "UPDATE_OFFER";
export const ERROR_UPDATE_OFFER = "ERROR_UPDATE_OFFER";

export const CLEAR_ALERT_OFFER = "CLEAR_ALERT_OFFER";

export const FETCH_OFFERS = "FETCH_OFFERS";
export const ACTION_FETCH_OFFERS = "ACTION_FETCH_OFFERS";
export const ERROR_FETCH_OFFERS = "ERROR_FETCH_OFFERS";

export const ACTION_EMAIL_OFFER = "ACTION_EMAIL_OFFER";
export const EMAIL_OFFER = "EMAIL_OFFER";
export const ERROR_EMAIL_OFFER = "ERROR_EMAIL_OFFER";

export const DELETE_OFFER = "DELETE_OFFER";
export const ERROR_DELETE_OFFER = "ERROR_DELETE_OFFER";

export const ACTION_PDF_OFFER = "ACTION_PDF_OFFER";
export const PDF_OFFER = "PDF_OFFER";
export const ERROR_PDF_OFFER = "ERROR_PDF_OFFER";

export const ACTION_FETCH_VALUES = "ACTION_FETCH_VALUES";
export const FETCH_VALUES = "FETCH_VALUES";
export const ERROR_FETCH_VALUES = "ERROR_FETCH_VALUES";

export const ACTION_COPY_OFFER = "ACTION_COPY_OFFER";
export const COPY_OFFER = "COPY_OFFER";
export const ERROR_COPY_OFFER = "ERROR_COPY_OFFER";

export const ACTION_DATA_FIELDS_OFFER = "ACTION_DATA_FIELDS_OFFER";
export const DATA_FIELDS_OFFER = "DATA_FIELDS_OFFER";
export const ERROR_DATA_FIELDS_OFFER = "ERROR_DATA_FIELDS_OFFER";

export const EMAIL_FILE_OFFER = "EMAIL_FILE_OFFER";
export const ERROR_EMAIL_FILE_OFFER = "ERROR_EMAIL_FILE_OFFER";

export const DELETE_OFFER_ATTACH = "DELETE_OFFER_ATTACH";
export const ERROR_DELETE_OFFER_ATTACH = "ERROR_DELETE_OFFER_ATTACH";





